import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import './index.scss';

import { AppState, IThemeState } from '../context';
import GalleryGrid2x2 from '../projects/components/GalleryGrid2x2';
import ProjectScrollWrapper from './components/ProjectScrollWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCircleChevronDown, faPlay } from '@fortawesome/free-solid-svg-icons';
import NewsBlock from '../news/components/NewsBlock';
import HeroLanding from './components/HeroLanding';
import { genUID } from '../lib/utility';
import PageContainer from '../common/PageContainer';


type PageProps = {
};
type PageState = {
    mounted: boolean;
    scrollY: number;
    itemsList: any[];
};
class LandingPage extends React.Component<PageProps, PageState, IThemeState> {
    context!: IThemeState;

    state: PageState = {
        mounted: false,
        scrollY: 0,
        itemsList: []
    };

    constructor(props: PageProps) {
        super(props);
        this.scrollHandler = this.scrollHandler.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        let i = 0;
        this.setState({
            mounted: true,
            itemsList: Array.from(Array(4).keys()).map(item => {
                return {
                    id: genUID(),
                    name: `Test ${item}`,
                    img:'https://picsum.photos/956/473?news-'+ i++
                }
            })
        });
        window.addEventListener('scroll', this.scrollHandler);
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.scrollHandler);
        window.removeEventListener('resize', this.onResize);
    }

    scrollHandler = () => {
        this.setState({
            scrollY: window.scrollY,
        });
    }

    onResize = () => {
        this.setState({
            
        });
    }

    render() {
        const { mounted, scrollY, itemsList } = this.state;
        return (
            <PageContainer>
                <AnimatePresence mode='wait'>
                    <motion.div
                        className='landing-page'
                        key={'landingPage'}
                        style={{
                            fontSize: 'calc(150/1920*100*1vw)',
                            lineHeight: 1,
                        }}
                    >
                        <HeroLanding pageHeight={'100vh'}>
                            <motion.div className='btn-watch-now'>
                                <button className='btn-black' onClick={() => {
                                    // scroll to #galleryGridContent + 85px with smooth
                                    // window.scrollTo(0, document.getElementById('galleryGridContent')!.getBoundingClientRect().y + 85);
                                    window.scrollTo({ top: document.getElementById('galleryGridContent')!.getBoundingClientRect().y + 85, behavior: 'smooth' })
                                }}>
                                    <FontAwesomeIcon icon={faPlay} />
                                    <span>Watch now</span>
                                </button>
                            </motion.div>
                        </HeroLanding>
                        <motion.div id="wrapperGalleryLanding" className='wrapperGalleryLanding'>
                            <motion.div className='gallery-view'>
                                <ProjectScrollWrapper topicChild={
                                    <motion.div className='topic-title' style={{
                                        maxWidth: '100vw'
                                    }}>
                                        <p>Highlight</p>
                                        <div className="hr"></div>
                                        <div className='icon-center'>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </div>
                                        <div className="hr"></div>
                                        <p>SCROLL</p>
                                    </motion.div>
                                }>
                                    <motion.div
                                        id="galleryGridContent"
                                        className='gallery-grid-2x2'
                                        key={'galleryGrid2x2'}
                                    >
                                        <GalleryGrid2x2 shouldAnimate={false} disabledLayoutId={true} items={itemsList}/>
                                    </motion.div>
                                    <motion.div className='see-more'>
                                        <motion.div layoutId='filter-button-container'>
                                            <Link to="/projects"><motion.button className='btn-floating'>See more</motion.button></Link>
                                        </motion.div>
                                    </motion.div>
                                </ProjectScrollWrapper>
                            </motion.div>
                        </motion.div>

                        <motion.div className='news-wrapper'>
                            <motion.div className='topic-title'>
                                <p>Latest News</p>
                                <div className="hr"></div>
                            </motion.div>
                            <motion.div className='news-list'>
                                {Array.from(Array(3).keys()).map(i => (
                                    <NewsBlock
                                        key={`news-landing-${i}`}
                                        img={'https://picsum.photos/350/200?news-random='+i}
                                        name='Lorem Ipsum is simply dummy text of the printing and'
                                        date='Jan, 26 2024'
                                        itemKey='/news1'
                                        link='/news/1'
                                    />
                                ))}
                                <div style={{
                                    marginTop: 20,
                                    textAlign: 'center',
                                }}>
                                    <Link to={'/news'}>
                                        <button className='btn-black'>
                                            <span>View all news</span>
                                        </button>
                                    </Link>
                                </div>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </AnimatePresence>
            </PageContainer>
        );
    }
}

LandingPage.contextType = AppState;

export default LandingPage;