import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import './index.scss';

import { AppState, IThemeState } from '../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn, faX } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faInstagramSquare, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import PageContainer from '../common/PageContainer';


type PageProps = {
};
type PageState = {
    mounted: boolean;
};
class ContactPage extends React.Component<PageProps, PageState, IThemeState> {
    context!: IThemeState;

    state: PageState = {
        mounted: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillUnmount(): void {
        
    }

    render() {
        const { mounted } = this.state;
        return (
            <PageContainer>
                <motion.div className='contact-page'>
                    <motion.div className='contact-wrapper'>
                        <motion.div className='contact-side'>
                            <motion.div className='content-container'>
                                <h1 className='contact-title'>Contact</h1>
                                <div className='address-line'>
                                    <p>9/1 หมู่ที่ 5 ถนนพหลโยธิน ตำบลคลองหนึ่ง</p>
                                    <p>อำเภอคลองหลวง จังหวัดปทุมธานี 12120</p>
                                </div>
                                <div className='contact-line'>
                                    <div className="contact-item">
                                        <p className='contact-topic'>โทรศัพท์</p>
                                        <p className='contact-value'>02 407 3888 ต่อ 2430</p>
                                    </div>

                                    <div className="contact-item">
                                        <p className='contact-topic'>แฟกซ์</p>
                                        <p className='contact-value'>02 407 3999</p>
                                    </div>
                                </div>
                                <br/><br/>
                                <div className='social-line'>
                                    <Link to="/" className='social-item'><FontAwesomeIcon icon={faFacebook} /></Link>
                                    <Link to="/" className='social-item'><FontAwesomeIcon icon={faYoutube} /></Link>
                                    <Link to="/" className='social-item'><FontAwesomeIcon icon={faTwitter} /></Link>
                                    <Link to="/" className='social-item'><FontAwesomeIcon icon={faInstagramSquare} /></Link>
                                </div>
                            </motion.div>
                        </motion.div>
                        <motion.div className='contact-side big-txt'>
                            <motion.div className='big-txt-container'>
                                <h1 className='big-txt'>BU</h1>
                                <h1 className='big-txt'>DM</h1>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                    <motion.div className='bottomLine'>
                        <p>Copyright © 2024 Bangkok University. All rights reserved</p>
                        <Link to='/admin' target='_blank'><FontAwesomeIcon icon={faSignIn} /></Link>
                    </motion.div>
                </motion.div>
            </PageContainer>
        );
    }
}

ContactPage.contextType = AppState;

export default ContactPage;