import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import style from './index.module.scss';

import { AppState, IThemeState } from '../../../context';


type PageProps = {
    itemKey: string;
    name: string;
    date: string;
    img: string;
    link: string;
};
type PageState = {
    mounted: boolean;
};
class NewsBlock extends React.Component<PageProps, PageState, IThemeState> {
    context!: IThemeState;

    state: PageState = {
        mounted: false,
    };

    componentDidMount() {
        
    }

    componentWillUnmount(): void {
        
    }

    render() {
        const { mounted } = this.state;

        const { itemKey, name, date, img, link } = this.props;

        return (
            <Link to={link}>
                <motion.div key={`newsblock-${itemKey}`} className={style.contentItem}>
                    <motion.div className={style.img} style={{
                        backgroundImage: `url(${img})`,
                    }} ></motion.div>
                    <motion.div className={style.info}>
                        <p className={style.name}>{name}</p>
                        <motion.div className={style.bottomInfo}>
                            <p className={style.date}>{date}</p>
                            <button className={style.readMore}>Read more</button>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </Link>
        );
    }
}

NewsBlock.contextType = AppState;

export default NewsBlock;