import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import style from './index.module.scss';

import { AppState, IThemeState } from '../../../context';
import MediaContent from '../MediaContent';
import { genUID } from '../../../lib/utility';


type PageProps = {
    shouldAnimate: boolean;
    items: any[];
    disabledLayoutId?: boolean;
};
type PageState = {
    propKey: string;
    mounted: boolean;
};
class GalleryGrid2x2 extends React.Component<PageProps, PageState, IThemeState> {
    context!: IThemeState;

    state: PageState = {
        propKey: genUID(),
        mounted: false,
    };

    componentDidMount() {
        
    }

    componentWillUnmount(): void {
        
    }

    render() {
        const { mounted, propKey } = this.state;

        const { disabledLayoutId, shouldAnimate } = this.props;

        let items = this.props.items;
        let maxItemsPerPage = 4;

        let itemsSeqList = [];
        for (let i = 0; i < items.length; i += maxItemsPerPage) {
            itemsSeqList.push(items.slice(i, i + maxItemsPerPage));
        }
        return (
            <motion.div key={`${propKey}-GalleryGrid2x2`}>
                {itemsSeqList.map((itemsSeq, iS) => (
                    <motion.div key={`${propKey}-GalleryGrid2x2-${iS}`} className={style.galleryGrid2x2}>
                        {itemsSeq.map((_, index) => (
                            <MediaContent
                                shouldAnimate={shouldAnimate}
                                key={`sub-list-${propKey}-GalleryGrid2x2-${iS}-${index}`}
                                projectId={_.id}
                                itemKey={`galleryGridMedia-${_.id}`}
                                name={`Test ${iS} ${index}`}
                                time={`15:15`}
                                img={_.img}
                                disabledLayoutId={disabledLayoutId}
                            />
                        ))}
                    </motion.div>
                ))}
            </motion.div>
        );
    }
}

GalleryGrid2x2.contextType = AppState;

export default GalleryGrid2x2;