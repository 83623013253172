import { useRef } from "react";
import { motion, useMotionValueEvent, useScroll, useSpring } from "framer-motion";

function ProjectScrollWrapper({ topicChild, children } : {
  topicChild?: React.ReactNode
  children: React.ReactNode
}) {

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['0 1', '1 1']
  });

  let min = 0.8;
  let max = 1;

  let crrScale = min + ((max - min) * scrollYProgress.get());
  // console.log(scrollYProgress.get())
  
  let minBorderRadius = 0;
  let maxBorderRadius = 80;

  // animate from 20 to zero
  let crrBorderRadius = minBorderRadius + ((maxBorderRadius - minBorderRadius) * scrollYProgress.get());
  return (
    <motion.div
      className="mobile-free-height"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
       <motion.div
        style={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
          scale: crrScale,
          transformOrigin: 'center top'
        }}
      >
        {topicChild}
      </motion.div>
      <motion.div
        ref={ref}
        style={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
        }}
      >
        <motion.div
          style={{
            width: "100%",
            scale: crrScale,
            transformOrigin: 'center top'
          }}
        >
          <motion.div
            style={{
            
              borderRadius: maxBorderRadius - crrBorderRadius,
              overflow: "hidden",
              zIndex: 50,
            }}
          >
            {children}
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default ProjectScrollWrapper;