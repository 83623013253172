import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import style from './index.module.scss';

import { AppState, IThemeState } from '../../../context';
import { genUID } from '../../../lib/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';


type PageProps = {

};
type PageState = {
    propKey: string;
    mounted: boolean;
    showDropdown: boolean;
    crrSelected: string;
    itemsList: any[];
};
class TabViewContent extends React.Component<PageProps, PageState, IThemeState> {
    context!: IThemeState;

    state: PageState = {
        propKey: genUID(),
        mounted: false,
        showDropdown: false,
        crrSelected: 'storyline',
        itemsList: [
            {
                title: 'Storyline',
                namespace: 'storyline',
                content: 'สตีฟ ชายคนหนึ่งที่ชอบดื่มชาเป็นชีวิตจิตใจ จนวันหนึ่งเขาได้สะสมตั๋วชาสูตรพิเศษที่จะมีเพียงแก้วเดียวจนครบ แต่ว่าวันที่เขาจะได้ดื่มชาสูตรพิเศษนั้น มันเป็นวันที่เกิดภัยพิบัติโลกแตก แอนิเมชั่นเรื่องนี้จะเล่าเรื่องราวเกี่ยวกับอุปสรรคต่างๆที่สตีฟต้องเผชิญในวันโลกแตก'
            },
            {
                title: 'Inspiration',
                namespace: 'inspiration',
                content: 'asdasdasd asd asd asd '
            },
            {
                title: 'Process',
                namespace: 'process',
                content: ' asd asfjklha lksdjfhpakwuehtfp aklnshdf '
            }
        ]
    };

    constructor(props: PageProps) {
        super(props);
        this.handleOthersClick = this.handleOthersClick.bind(this);
    }

    componentDidMount() {
        window.addEventListener('click', this.handleOthersClick);
    }

    componentWillUnmount(): void {
        window.removeEventListener('click', this.handleOthersClick);
    }

    handleOthersClick = (e: any) => {
        console.log('handleOthersClick', e)
        if(e.target.classList.contains('dropdown-crr-select')
            && e.target.getAttribute('data-prop-key') === this.state.propKey
        ) return;
        
        this.setState({
            showDropdown: false
        });
    }

    getParentNode(e: any) {
        return e.target.parentElement.parentElement;
    }

    render() {
        const { mounted, propKey, showDropdown, itemsList } = this.state;

        let {
        
        } = this.props;

        return (
            <motion.div className='tab-container'>
                <motion.div
                    className='tab-list'
                >
                    {itemsList.map((item, index) => {
                        return <motion.div key={`tab-${index}`}
                            className={`tab-item ${item.namespace === this.state.crrSelected ? 'active' : ''}`}
                            onClick={() => {
                                this.setState({
                                    crrSelected: item.namespace
                                })
                            }}
                        >
                            {item.title}
                        </motion.div>
                    })}
                </motion.div>
                {itemsList.map((item, index) => {
                    return <motion.div key={`tab-${index}`}
                        className={`tab-content ${item.namespace === this.state.crrSelected ? 'active' : ''}`}
                    >
                        {item.content}
                    </motion.div>
                })}
            </motion.div>
        );
    }
}

TabViewContent.contextType = AppState;

export default TabViewContent;