import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import style from './index.module.scss';

import { AppState, IThemeState } from '../../../context';
import MediaContent from '../MediaContent';


type PageProps = {
    shouldAnimate: boolean;
    items: any[];
    disabledLayoutId?: boolean;
};
type PageState = {
    mounted: boolean;
};
class GalleryGrid3x3 extends React.Component<PageProps, PageState, IThemeState> {
    context!: IThemeState;

    state: PageState = {
        mounted: false,
    };

    componentDidMount() {
        
    }

    componentWillUnmount(): void {
        
    }

    render() {
        const { mounted, } = this.state;
        const { shouldAnimate } = this.props;

        let items = this.props.items;
        let maxItemsPerPage = 9;

        let itemsSeqList = [];
        for (let i = 0; i < items.length; i += maxItemsPerPage) {
            itemsSeqList.push(items.slice(i, i + maxItemsPerPage));
        }
        return (
            <>
                {itemsSeqList.map((itemsSeq, iS) => (
                    <motion.div key={`GalleryGrid3x3-${iS}`} className={style.galleryGrid3x3}>
                        {itemsSeq.map((_, index) => (
                            <MediaContent
                                shouldAnimate={shouldAnimate}
                                key={`sub-list-galleryGrid3x3-${iS}-${index}`}
                                projectId={_.id}
                                itemKey={`galleryGridMedia-${_.id}`}
                                name={`Test ${iS} ${index}`}
                                time={`15:15`}
                                img={_.img}
                                disabledLayoutId={this.props.disabledLayoutId}
                            />
                        ))}
                    </motion.div>
                ))}
            </>
        );
    }
}

GalleryGrid3x3.contextType = AppState;

export default GalleryGrid3x3;