import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import './index.scss';

import { AppState, IThemeState } from '../context';
import HeroLanding from '../landing/components/HeroLanding';
import NewsBlock from '../news/components/NewsBlock';
import PageContainer from '../common/PageContainer';


type PageProps = {
};
type PageState = {
    mounted: boolean;
};
class NewsPage extends React.Component<PageProps, PageState, IThemeState> {
    context!: IThemeState;

    state: PageState = {
        mounted: false,
    };

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        });
    }

    componentWillUnmount(): void {
        
    }

    render() {
        const { mounted } = this.state;
        return (
            <PageContainer>
                <motion.div className='news-page'>
                    <HeroLanding pageHeight={'80vh'} />
                    <motion.div className='news-wrapper'>
                        <h1 className='news-title'>NEWS</h1>
                        <motion.div className='news-list'>
                            {Array.from(Array(10).keys()).map(i => (
                                <NewsBlock
                                    key={`news-landing-${i}`}
                                    img={'https://picsum.photos/350/200?news-random='+i}
                                    name='Lorem Ipsum is simply dummy text of the printing and'
                                    date='Jan, 26 2024'
                                    itemKey={`/news/${i+1}`}
                                    link='/news/1'
                                />
                            ))}
                        </motion.div>
                    </motion.div>
                </motion.div>
            </PageContainer>
        );
    }
}

NewsPage.contextType = AppState;

export default NewsPage;