import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import DOMPurify from "dompurify";

import './index.scss';

import { AppState, IThemeState } from '../context';
import { withRouter, WithRouterProps } from '../common/withRouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faMailBulk, faPlay } from '@fortawesome/free-solid-svg-icons';
import TabViewContent from './components/tab-view';
import { faFacebook, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Footer from '../common/Footer';
import PageContainer from '../common/PageContainer';

interface Params {
    projectId: string;
}
  
type PageProps = WithRouterProps<Params>;

type PageState = {
    mounted: boolean;
    title: string;
    date: string;
    img: string;
    content: string;
    videoWidth: number;
    videoHeight: number;
};
class ProjectViewPage extends React.Component<PageProps, PageState, IThemeState> {
    context!: IThemeState;

    state: PageState = {
        mounted: false,
        title: '',
        date: '',
        img: '',
        content: '',
        videoWidth: 905,
        videoHeight: 0,
    };

    ref = React.createRef<HTMLDivElement>();

    previousScrollY = document.getElementById('project-view-container')?.scrollTop || 0;

    constructor(props: PageProps) {
        super(props);

        this.onResize = this.onResize.bind(this);
        this.previousScrollY = document.getElementById('project-view-container')?.scrollTop || 0;
        // window.scrollTo(0, 0);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.onResize);
        this.onResize();
        this.context.setHeaderTxtColor('white');
        this.setState({
            mounted: true
        })
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.onResize);
        this.context.setHeaderTxtColor('');
        if(this.state.mounted && window.location.pathname === '/projects') {
            setTimeout(() => {
                if(document.getElementById('project-view-container')) {
                    // this.scrollByY(this.previousScrollY, 150);
                    document.getElementById('project-view-container')?.scrollTo({
                        top: this.previousScrollY,
                        behavior: 'instant'
                    })
                } else {
                    window.scrollTo(0, this.previousScrollY);
                }
            }, 50);
        }
    }

    async scrollByY(y: number, time: number) {
        const start = performance.now()
        const startY = window.scrollY
        const endY = startY + y
        while (performance.now() < start + time) {
          const progress = (performance.now() - start) / time
          document.getElementById('project-view-container')?.scrollTo(0, startY + y * progress)
          // wait for the next frame
          await new Promise(requestAnimationFrame)
        }
        document.getElementById('project-view-container')?.scrollTo(0, endY)
      }

    onResize = () => {
        if(this.ref.current) {
            // 16:9
            this.setState({
                videoWidth: this.ref.current.offsetWidth,
                videoHeight: this.ref.current.offsetWidth * 0.5625
            });
        }
    }

    render() {
        const {
            mounted,
            title,
            date,
            img,
            content,
            videoWidth,
            videoHeight
        } = this.state;

        // const { projectId } = this.props.match.params;
        
        // console.log(this.props.match.params)
        return (
        <PageContainer>
            <motion.div className='project-view-page'
                exit={{
                    opacity: 0,
                    transition: { duration: 0.2 }
                }}
            >
                <motion.div className='blog-wrapper'>
                    <motion.div className='blog-contain'>
                        <motion.div className='display-panel'>
                            <motion.div className='video-wrapper' ref={this.ref}
                                layoutId={`video-wrapper-${this.props.match.params.projectId}`}
                                style={{
                                    backgroundImage: `url(https://picsum.photos/956/473?news-${this.props.match.params.projectId})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            >
                                <motion.div
                                    style={{
                                        backgroundColor: 'black'
                                    }}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.25, delay: 0.5 }}
                                >
                                    <iframe
                                        className='videoPlayer'
                                        src="https://player.vimeo.com/video/946693818?h=4bf182aa5c?autoplay=1"
                                        width={videoWidth}
                                        height={videoHeight}
                                        frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </motion.div>
                                <motion.div className='video-list'
                                    initial={{ maxHeight: 0 }}
                                    animate={{ maxHeight: 120 }}
                                    transition={{ duration: 0.25, delay: 0.75 }}
                                >
                                    <motion.div className='videoItem active'
                                        style={{
                                            backgroundImage: `url(https://vumbnail.com/946693818.jpg)`
                                        }}
                                    >
                                        <FontAwesomeIcon className='icon' icon={faPlay} />
                                    </motion.div>
                                    <motion.div className='videoItem'
                                        style={{
                                            backgroundImage: `url(https://vumbnail.com/944615981.jpg)`
                                        }}
                                    >
                                        <FontAwesomeIcon className='icon' icon={faPlay} />
                                    </motion.div>
                                    <motion.div className='videoItem'
                                        style={{
                                            backgroundImage: `url(https://vumbnail.com/954480718.jpg)`
                                        }}
                                    >
                                        <FontAwesomeIcon className='icon' icon={faPlay} />
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                            <motion.div className='info'
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                            >
                                <p className='title'>Tea Time End Time</p>
                                <motion.div className='meta-line'>
                                    <FontAwesomeIcon icon={faEye} />
                                    <p>131,583</p>
                                    <p style={{ margin: '0 10px' }}>•</p>
                                    <p>2013</p>
                                    <p style={{ margin: '0 10px' }}>•</p>
                                    <p>3D Animation</p>
                                </motion.div>
                            </motion.div>
                            <br/>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                            >
                                <TabViewContent />
                            </motion.div>
                        </motion.div>

                        <motion.div
                            className='side-panel'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.5 }}
                        >
                            <motion.div className='related-box' style={{
                                height: videoHeight
                            }}>
                                <motion.div className='group-top'>
                                    <p className='related-title'>Related Projects</p>
                                    <motion.div className='content-list'>
                                        {Array.from(Array(3).keys()).map(i => (
                                            <Link to={'/project/1'} key={'project-' + i}>
                                                <motion.div className='content-item'>
                                                    <motion.div className='thumbnail'
                                                        style={{
                                                            backgroundImage: `url(${'https://picsum.photos/128/72?news-random=' + i})`,
                                                        }}
                                                    ></motion.div>
                                                    <motion.div className='info'>
                                                        <p className='title'>Lorem Ipsum is simply dummy text of the printing and</p>
                                                        <p className='categoryName'>3D Animation</p>
                                                        <motion.div className='meta-line'>
                                                            <FontAwesomeIcon icon={faEye} />
                                                            <p>131,583</p>
                                                            <p style={{ margin: '0 10px' }}>•</p>
                                                            <p>2013</p>
                                                        </motion.div>
                                                    </motion.div>
                                                </motion.div>
                                            </Link>
                                        ))}
                                    </motion.div>
                                </motion.div>
                                <button className='btn-view-all'>See all related projects</button>
                            </motion.div>
                            
                            <br/>
                            <motion.div className='team-info'>
                                <p className='title'>Creators</p>

                                <motion.div className='team-list'>
                                    {Array.from(Array(3).keys()).map(i => (
                                        <motion.div className='team-item' key={'team-item-' + i}>
                                            <motion.div className='profile-img'></motion.div>
                                            <motion.div className='profile-info'>
                                                <p className='name'>Firstname Lastname</p>
                                                <p className='role'>Position Role</p>
                                                <p className='phone'>099-999-9999</p>
                                                <motion.div className='contact-list'>
                                                    <motion.div className='contact-item'>
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </motion.div>
                                                    <motion.div className='contact-item'>
                                                        <FontAwesomeIcon icon={faFacebookF} />
                                                    </motion.div>
                                                    <motion.div className='contact-item'>
                                                        <FontAwesomeIcon icon={faInstagram} />
                                                    </motion.div>
                                                </motion.div>
                                            </motion.div>
                                        </motion.div>
                                    ))}
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </motion.div>
        </PageContainer>
        );
    }
}

ProjectViewPage.contextType = AppState;

export default withRouter(ProjectViewPage);
// export default ProjectViewPage;