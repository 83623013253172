import * as React from 'react';
import { checkIsMobile, isSafari, readCookie, writeCookie } from './lib/utility';

export type IExhibitionItem = {
    img: string,
    name: string,
    link: string
}
export interface IThemeState {
    isCanUseEffect: boolean
    headerTxtColor: string
    setHeaderTxtColor: (color: string) => void
    exhibitions: IExhibitionItem[]
    crrLinkExhibitions: string
    setCrrLinkExhibitions: (link: string) => void
}

export const AppState = React.createContext({
    isCanUseEffect: checkIsMobile() || isSafari(),
});

type PageProps = {
    children: React.ReactNode
};
type PageState = {
    
};
class ContextWraper extends React.Component<PageProps, PageState> {
    

    state: IThemeState = {
        isCanUseEffect: !checkIsMobile() && !isSafari(),
        headerTxtColor: '',
        setHeaderTxtColor: (color: string) => {
            this.setState({ headerTxtColor: color });
        },
        crrLinkExhibitions: '',
        setCrrLinkExhibitions: (link: string) => {
            writeCookie('crrLinkExhibitions', link);
            this.setState({ crrLinkExhibitions: link });
            window.location.reload();
        },
        exhibitions: [
            {
                img: '',
                name: 'Exhibition 2024',
                link: '/exhibition2024'
            },
            {
                img: '',
                name: 'Exhibition 2023',
                link: '/exhibition2023'
            },
            {
                img: '',
                name: 'Exhibition 2022',
                link: '/exhibition2022'
            }
        ]
    };

    constructor(props: PageProps) {
        super(props);
    }

    componentDidMount() {
        let isCanNotSmooth = !checkIsMobile() && !isSafari()
        this.setState({
            isCanNotSmooth,
            crrLinkExhibitions: readCookie('crrLinkExhibitions') ?? this.state.exhibitions[0].link
        });
    }

    render() {
        return (
            <AppState.Provider value={this.state}>
                {this.props.children}
            </AppState.Provider>
        );
    }
}

export default ContextWraper;