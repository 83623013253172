import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import style from './index.module.scss';

import { AppState, IThemeState } from '../../../context';


type PageProps = {
    shouldAnimate: boolean;
    projectId: string | number;
    itemKey: string;
    name: string;
    time: string;
    img: string;
    disabledLayoutId?: boolean;
};
type PageState = {
    mounted: boolean;
};
class MediaContent extends React.Component<PageProps, PageState, IThemeState> {
    context!: IThemeState;

    state: PageState = {
        mounted: false,
    };

    componentDidMount() {
        this.setState({ mounted: true });
    }

    componentWillUnmount(): void {
        
    }

    render() {
        const { mounted } = this.state;

        const { shouldAnimate, projectId, itemKey, name, time, img, disabledLayoutId } = this.props;

        return (
            <Link className={style.linkWrapper} to={`/project/${projectId}`}>
                <motion.div key={`mediaItem-${itemKey}`} className={style.contentItem}
                    layoutId={disabledLayoutId ? undefined : `mediaItem-${itemKey}`}

                    initial={!shouldAnimate ? {} : {
                        opacity: 0,
                        scale: .75
                    }}
                    animate={!shouldAnimate ? {} : {
                        opacity: 1,
                        scale: 1,
                        transition: { 
                            duration: .75,
                            ease: [0.5, 0.025, 0, 1],
                        }
                    }}
                >
                    <motion.div className={style.img}
                        layoutId={disabledLayoutId ? undefined : `video-wrapper-${projectId}`}
                        style={{ backgroundImage: `url(${img})` }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.25}}
                    ></motion.div>
                    <motion.div className={style.info}>
                        <motion.p className={style.item}
                            layoutId={disabledLayoutId ? undefined : `mediaItem-${itemKey}-name`}
                        >{name}</motion.p>
                        <motion.p className={style.item}
                            layoutId={disabledLayoutId ? undefined : `mediaItem-${itemKey}-time`}
                        >{time}</motion.p>
                    </motion.div>
                </motion.div>
            </Link>
        );
    }
}

MediaContent.contextType = AppState;

export default MediaContent;