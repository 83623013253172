import { useRef } from "react";
import { motion, useMotionValueEvent, useScroll, useSpring } from "framer-motion";

function FooterScrollWrapper({ children } : {
  children: React.ReactNode
}) {

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['0 1', '1 1']
  });

  let min = -100;
  let max = 110;

  let crrY = min + ((max - min) * scrollYProgress.get());
  // console.log(crrY)
  
  return (
    <motion.div
      ref={ref}
      style={{
        width: "100%",
      }}
    >
      <motion.div
        style={{
          width: "100%",
          y: crrY,
          transformOrigin: 'center top'
        }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
}

export default FooterScrollWrapper;