import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import './index.scss';

import { AppState, IThemeState } from '../context';
import GalleryGrid3x3 from './components/GalleryGrid3x3';
import GalleryGrid2x2 from './components/GalleryGrid2x2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBorderAll, faChevronDown, faChevronUp, faTableCells } from '@fortawesome/free-solid-svg-icons';
import DropdownFilter from './components/DropdownFilter';
import { genUID, readCookie, writeCookie } from '../lib/utility';
import PageContainer from '../common/PageContainer';


type PageProps = {
};
type PageState = {
    mounted: boolean;
    showExhibitionYearDropdown: boolean;
    showCategoryDropdown: boolean;
    crrCategory: string;
    viewMode: string;
    itemsList: any[];
};
class ProjectPage extends React.Component<PageProps, PageState, IThemeState> {
    context!: IThemeState;

    state: PageState = {
        mounted: false,
        showExhibitionYearDropdown: false,
        showCategoryDropdown: false,
        crrCategory: 'all',
        viewMode: readCookie('project-filter-view-mode') || '3x3',
        itemsList: []
    };

    constructor(props: PageProps) {
        super(props);
        this.changeViewMode = this.changeViewMode.bind(this);
    }

    componentDidMount() {
        let i = 0;
        this.setState({
            mounted: true,
            viewMode: readCookie('project-filter-view-mode') || '3x3',
            itemsList: Array.from(Array(9*6).keys()).map(item => {
                let uid = i++;
                return {
                    id: uid,
                    name: `Test ${item}`,
                    img:'https://picsum.photos/956/473?news-'+ uid
                }
            })
        });
    }

    componentWillUnmount(): void {
        
    }

    changeViewMode(mode: string) {
        this.setState({
            viewMode: mode
        })

        // readCookie('project-filter-view-mode')
        writeCookie('project-filter-view-mode', mode)
    }

    render() {
        const { mounted, viewMode, itemsList, showExhibitionYearDropdown, showCategoryDropdown, crrCategory } = this.state;

        const { crrLinkExhibitions }: IThemeState = this.context;

        console.log('crrLinkExhibitions', crrLinkExhibitions)

        let crrItem = this.context.exhibitions.find(item => item.link === crrLinkExhibitions) ?? this.context.exhibitions[0];

        return (
            <PageContainer>
                <motion.div className='project-page'
                    id="project-view-container"
                >
                    { viewMode === '3x3' && <GalleryGrid3x3 shouldAnimate={!mounted} items={itemsList}/> }
                    { viewMode === '2x2' && <GalleryGrid2x2 shouldAnimate={!mounted} items={itemsList}/> }

                    <motion.div className='floating-filter'>
                        <motion.div className='float-container'
                            layoutId='filter-button-container'
                        >
                            <Link to={'/'}>
                                <motion.div className='exhibition-logo hide-on-mobile'>
                                    <motion.div className='img' style={{
                                        backgroundImage: 'url(/exhibitions/logo.png)'
                                    }}></motion.div>
                                </motion.div>
                            </Link>

                            <DropdownFilter
                                items={this.context.exhibitions.map(item => ({
                                    name: item.name,
                                    value: item.link,
                                    img: item.img
                                }))}
                                onSelect={(value) => {
                                    this.context.setCrrLinkExhibitions(value);
                                }}
                                value={crrItem?.link}
                            />
                            
                            <DropdownFilter
                                items={[
                                    {
                                        name: "All",
                                        value: "all"
                                    },
                                    {
                                        name: "Test 11",
                                        value: "test-1"
                                    },
                                    {
                                        name: "Test 2",
                                        value: "test-2"
                                    }
                                ]}
                                onSelect={(value) => {
                                    this.setState({
                                        crrCategory: value
                                    })
                                }}
                                value={crrCategory}
                            />

                            <motion.div className='iconPanel hide-on-mobile'>
                                <FontAwesomeIcon className={`icon ${viewMode === '2x2' ? 'active' : ''}`} icon={faBorderAll}
                                    onClick={() => {
                                        this.changeViewMode('2x2')
                                    }}
                                />
                                <FontAwesomeIcon className={`icon ${viewMode === '3x3' ? 'active' : ''}`} icon={faTableCells}
                                    onClick={() => {
                                        this.changeViewMode('3x3')
                                    }}
                                />
                            </motion.div>
                            <Link to={'/news'} className='hide-on-mobile'>
                                <motion.button className='button-item'>News</motion.button>
                            </Link>
                            <Link to={'/contact'} className='hide-on-mobile'>
                                <motion.button className='button-item'>Contact</motion.button>
                            </Link>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </PageContainer>
        );
    }
}

ProjectPage.contextType = AppState;

export default ProjectPage;