import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import style from './index.module.scss';

import { AppState, IThemeState } from '../../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';


type PageProps = {
    pageHeight: string;
    children?: React.ReactNode
};
type PageState = {
    mounted: boolean;
    scrollY: number;
};
class HeroLanding extends React.Component<PageProps, PageState, IThemeState> {
    context!: IThemeState;

    state: PageState = {
        mounted: false,
        scrollY: 0,
    };

    constructor(props: PageProps) {
        super(props);
        this.scrollHandler = this.scrollHandler.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', this.scrollHandler);
        this.scrollHandler();
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.scrollHandler);
    }

    scrollHandler = () => {
        this.setState({
            scrollY: window.scrollY,
        });
    }

    render() {
        const { mounted, scrollY } = this.state;

        const { pageHeight, children } = this.props;
        return (
            <>
                <motion.div className={style['hero-landing']}
                    style={{
                        position: 'fixed',
                        top: -(scrollY / 5),
                        left: 0,
                        backgroundImage: 'url(/img/2024-banner.png)'
                    }}
                >
                    { children }
                </motion.div>
                <motion.div className={style['hero-landing']}
                    style={{
                        height: pageHeight
                    }}
                ></motion.div>
            </>
        );
    }
}

HeroLanding.contextType = AppState;

export default HeroLanding;