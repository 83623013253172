import * as React from 'react';
import {
    useLocation,
    Routes,
	Route
} from 'react-router-dom';
import { LayoutGroup, AnimatePresence, motion } from 'framer-motion';

// Components
import Header from './common/Header';
import LoadingIcon from './common/LoadingIcon';

// Pages
import LandingPage from './landing';

import ContextWraper, { AppState, IThemeState } from './context';

import './App.scss';
import { checkIsMobile } from './lib/utility';
import ProjectPage from './projects';
import NewsPage from './news';
import Footer from './common/Footer';
import NewsReadPage from './news-read';
import ProjectViewPage from './project-view';
import ScrollToTop from './common/ScrollToTop';
import ContactPage from './contact';

export default function App() {
	const location = useLocation();
	  
	return (
        <LayoutGroup key={'mainLayoutGroup'}>
            <ContextWraper>
                <AppClass>
                    {/* <ScrollToTop /> */}
                    <AnimatePresence mode='wait' key="routeAnimated">
                        <Routes location={location} key={location.pathname}>
                            <Route key={'landing'} path="/" element={<LandingPage key={'landing'}/>} />
                            <Route key={'projects'} path="/projects" element={<ProjectPage key={'project'}/>} />
                            <Route key={'project-view'} path="/project/:projectId" element={<ProjectViewPage key={'news-read'}/>} />
                            <Route key={'news'} path="/news" element={<NewsPage key={'news'}/>} />
                            <Route key={'news-read'} path="/news/:newsId" element={<NewsReadPage key={'news-read'}/>} />
                            <Route key={'contact'} path="/contact" element={<ContactPage key={'contact-page'}/>} />
                            <Route key={'404'} path="*" element={<div
                                style={{
                                    width: '100%',
                                    height: '100vh',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                }}
                            >
                                <h1>404</h1>
                            </div>} />
                        </Routes>
                    </AnimatePresence>
                </AppClass>
            </ContextWraper>
        </LayoutGroup>
	);
}

declare global {
    interface Window {
        onLoadSuccessfully: Function;
        onFirstMounted: boolean;
        isMobile: boolean;
    }
}

interface IAppClassProps {
    children?: React.ReactNode;
}

interface IAppClassState {
    isLoaded: boolean;
}
class AppClass extends React.Component<IAppClassProps, IAppClassState, IThemeState> {
    context!: IThemeState;

    state: IAppClassState = {
        isLoaded: false,
    }

    constructor(props: IAppClassProps) {
        super(props);
        window.isMobile = checkIsMobile();
    }

    componentDidMount(): void {
        document.getElementById('preloaderTxt')?.remove();
        window.onLoadSuccessfully = () => {
            window.onFirstMounted = true;
            setTimeout(() => {
                this.setState({
                    isLoaded: true,
                });
            }, 1000);
        }
    }
    render() {
        return (
            <>
                {this.state.isLoaded && this.props.children}
                <AnimatePresence mode='wait' key="landing-loader">
                    {!this.state.isLoaded && <motion.div className="preloadOverlay"
                        animate={{
                            opacity: 1,
                            transition: {
                                duration: 1,
                            }
                        }}
                        exit={{
                            opacity: 0,
                        }}
                    >
                        <motion.div className="loadingCenter">
                            <motion.div className="sub"
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: 1,
                                    }
                                }}
                                exit={{
                                    opacity: 0,
                                    scale: 0,
                                }}
                            >
                                <LoadingIcon/>
                            </motion.div>
                        </motion.div>
                    </motion.div>}
                </AnimatePresence>
            </>
        );
    }
}

AppClass.contextType = AppState;