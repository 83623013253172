/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion, stagger } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faArrowDown, faArrowUp, faAt, faChevronDown, faChevronUp, faSignIn } from '@fortawesome/free-solid-svg-icons'

import { AppState, IThemeState, IExhibitionItem } from '../context';
import Header from './Header';
import Footer from './Footer';


type Props = {
    children: JSX.Element
};
type State = {
    mounted: boolean
    scrollY: number
};

class PageContainer extends React.Component<Props, State, IThemeState> {
    context!: IThemeState;

    state: State = {
        mounted: false,
        scrollY: 0
    };

    containerVariant = {
        hidden: { 
            
        },
        show: {
            // transition: {
            //     staggerChildren: .2,
            //     staggerDirection: 1
            // }
        }
    }

    constructor(props: Props) {
        super(props);
        this.onScrollHandler = this.onScrollHandler.bind(this);
    }

    componentDidMount(): void {
        window.addEventListener('scroll', this.onScrollHandler);

        setTimeout(() => {
            this.setState({
                mounted: true
            })
        }, 1000);
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.onScrollHandler);
    }

    onScrollHandler = () => {
        this.setState({
            scrollY: window.scrollY
        })
    }

    render() {
        const { isCanUseEffect, crrLinkExhibitions }: IThemeState = this.context;
        // console.log(this.context)
        const { mounted, scrollY } = this.state;

        const { children } = this.props;

        let isProjectPage = window.location.pathname === "/projects" || window.location.pathname.includes('/project/')

        return (<>
                <motion.div
                    style={{
                        transformOrigin: 'center 0',
                        position: 'relative',
                        zIndex: 10,
                    }}
                    initial={isProjectPage ? {} : {
                        opacity: 0,
                        y: 100,
                        scale: 0.9,
                    }}
                    animate={isProjectPage ? {} : {
                        opacity: 1,
                        y: 0,
                        scale: 1,
                        transition: {
                            duration: .75,
                            ease: [0.5, 0.025, 0, 1],
                        }
                    }}
                    exit={isProjectPage ? {} : {
                        opacity: 0,
                        scale: 0.9,
                        y: 100,
                        transition: {
                            duration: .15,
                        }
                    }}
                >
                    <Header key={'header'} />
                    {children}
                    <Footer key={'footer'} />

                    {!isProjectPage && 
                        <motion.div
                            className='scrollToTopBtn'
                            style={{
                                pointerEvents: scrollY > 100 ? 'auto' : 'none',
                            }}
                            animate={{
                                opacity: scrollY > 100 ? 1 : 0,
                            }}
                        >
                            <motion.div
                                className='scrollToTopBtnInner'
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <FontAwesomeIcon icon={faChevronUp} />
                            </motion.div>
                        </motion.div>
                    }
                </motion.div>
            </>
        );
    }
}

PageContainer.contextType = AppState;

export default PageContainer;