/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion, stagger } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faArrowDown, faArrowUp, faAt, faBars, faBurger, faChevronDown, faChevronUp, faX } from '@fortawesome/free-solid-svg-icons'

import { AppState, IThemeState, IExhibitionItem } from '../../context';

import './header.scss';

type Props = {
    
};
type State = {
    mobileMenuOpen: boolean
    showExhibitionDropdown: boolean
    scrollY: number
};

class Header extends React.Component<Props, State, IThemeState> {
    context!: IThemeState;

    state: State = {
        mobileMenuOpen: false,
        showExhibitionDropdown: false,
        scrollY: window.scrollY
    };

    containerVariant = {
        hidden: { 
            
        },
        show: {
            // transition: {
            //     staggerChildren: .2,
            //     staggerDirection: 1
            // }
        }
    }

    constructor(props: Props) {
        super(props);
        this.ExhibitionItem = this.ExhibitionItem.bind(this);
        this.onScrollHandler = this.onScrollHandler.bind(this);
    }

    componentDidMount(): void {
        window.addEventListener('click', this.handleOthersClick);
        window.addEventListener('scroll', this.onScrollHandler);
        this.onScrollHandler();
    }

    componentWillUnmount(): void {
        window.removeEventListener('click', this.handleOthersClick);
        window.removeEventListener('scroll', this.onScrollHandler);
    }

    handleOthersClick = (e: any) => {
        // console.log('handleOthersClick', e)
        if(e.target.classList.contains('bannerContainer')) return;
        
        this.setState({
            showExhibitionDropdown: false
        });
    }
    
    getParentNode(e: any) {
        return e.target.parentElement.parentElement;
    }

    onScrollHandler = () => {
        this.setState({
            scrollY: window.scrollY
        })
    }

    render() {
        const { isCanUseEffect, headerTxtColor, crrLinkExhibitions }: IThemeState = this.context;
        const { scrollY, mobileMenuOpen } = this.state;
        // console.log(this.context)

        let isShow = true; //window.location.pathname !== "/projects";

        return (
            <motion.div className={`header ${mobileMenuOpen ? 'active' : ''}`}
                style={{
                    display: isShow ? '' : 'none',
                }}
            >
                <motion.div
                    className='backdrop-floating'
                    initial={{
                        y: -100,
                        opacity: 0
                    }}
                    animate={scrollY > 100 ? {
                        y: 0,
                        opacity: 1
                    } : {
                        y: -100,
                        opacity: 0
                    }}

                    transition={{
                        duration: .5,
                        ease: [0.5, 0.025, 0, 1],
                    }}
                ></motion.div>
                <Link className='mobile-logo' to={'/'}>
                    
                </Link>
                <motion.div className={`mobile-hamburger ${mobileMenuOpen ? 'active' : ''}`}
                    onClick={() => {
                        this.setState({
                            mobileMenuOpen: !this.state.mobileMenuOpen
                        })
                    }}
                >
                    { !mobileMenuOpen && <FontAwesomeIcon icon={faBars} />}
                    { mobileMenuOpen && <FontAwesomeIcon icon={faX} />}
                </motion.div>
                <motion.div className={`header-container ${mobileMenuOpen ? 'active' : ''}`}>
                    <ul style={{
                        color: headerTxtColor
                    }}
                        className={headerTxtColor}
                    >
                        <li><Link className={window.location.pathname === '/' ? 'active' : ''} to={'/'}>Home</Link></li>
                        <li><Link className={window.location.pathname === '/projects' ? 'active' : ''} to={'/projects'}>Projects</Link></li>
                        <this.ExhibitionItem crrLink={crrLinkExhibitions} items={this.context.exhibitions}/>
                        <li><Link className={window.location.pathname === '/news' ? 'active' : ''} to={'/news'}>News</Link></li>
                        <li><Link className={window.location.pathname === '/contact' ? 'active' : ''} to={'/contact'}>Contact</Link></li>
                    </ul>
                </motion.div>
            </motion.div>
        );
    }

    ExhibitionItem({
        crrLink,
        items,
    }: {
        crrLink: string,
        items: IExhibitionItem[],
    }) {

        let crrItem = items.find(item => item.link === crrLink);

        if(!crrItem) {
            return <></>;
        }

        const { showExhibitionDropdown } = this.state;

        return (
            <motion.div
                className="exhibitionItem"
            >
                <motion.div className={`bannerContainer ${showExhibitionDropdown ? 'active' : ''}`}
                    onClick={() => {
                        this.setState({
                            showExhibitionDropdown: !showExhibitionDropdown
                        })
                    }}
                >
                    <div className='img'
                        style={{
                            backgroundImage: `url(${crrItem.img}.png)`,
                        }}
                    ></div>
                    <p className='name'>{crrItem.name}</p>
                    <motion.div className='arrow'>
                        {showExhibitionDropdown && <FontAwesomeIcon icon={faChevronUp} />}
                        {!showExhibitionDropdown && <FontAwesomeIcon icon={faChevronDown} />}
                    </motion.div>
                </motion.div>

                <motion.div className={`exhibitionDropdown ${showExhibitionDropdown ? 'active' : ''}`}>
                    <ul>
                        {items.filter(item => item.link !== crrLink).map(item => (
                            <li key={item.link}>
                                <img src={item.img} alt='exhibition logo' />
                                <div onClick={() => {
                                    this.context.setCrrLinkExhibitions(item.link);
                                    // this.setState({
                                    //     showExhibitionDropdown: false
                                    // })
                                }}>{item.name}</div>
                            </li>
                        ))}
                    </ul>
                </motion.div>
            </motion.div>
        );
    }
}

Header.contextType = AppState;

export default Header;