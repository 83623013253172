/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion, stagger } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faArrowDown, faArrowUp, faAt, faChevronDown, faChevronUp, faSignIn } from '@fortawesome/free-solid-svg-icons'

import { AppState, IThemeState, IExhibitionItem } from '../../context';

import './footer.scss';
import FooterScrollWrapper from './FooterScrollWrapper';

type Props = {
    
};
type State = {
    mounted: boolean
    scrollY: number
};

class Footer extends React.Component<Props, State, IThemeState> {
    context!: IThemeState;

    state: State = {
        mounted: false,
        scrollY: 0
    };

    containerVariant = {
        hidden: { 
            
        },
        show: {
            // transition: {
            //     staggerChildren: .2,
            //     staggerDirection: 1
            // }
        }
    }

    constructor(props: Props) {
        super(props);
        this.onScrollHandler = this.onScrollHandler.bind(this);
    }

    componentDidMount(): void {
        window.addEventListener('scroll', this.onScrollHandler);

        setTimeout(() => {
            this.setState({
                mounted: true
            })
        }, 1000);
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.onScrollHandler);
    }

    onScrollHandler = () => {
        this.setState({
            scrollY: window.scrollY
        })
    }

    render() {
        const { isCanUseEffect, crrLinkExhibitions }: IThemeState = this.context;
        // console.log(this.context)
        const { mounted, scrollY } = this.state;

        // if(!mounted) {
        //     return <></>;
        // }

        let isShow = window.location.pathname !== "/projects" && !window.location.pathname.includes('/project/') && window.location.pathname !== "/contact";

        return (
            <motion.div className='footer'
                style={{
                    display: isShow ? '' : 'none',
                }}
            >
                <FooterScrollWrapper>
                    <h1 className='siteBigTitle'>BUDM SHOWPRO</h1>
                </FooterScrollWrapper>
                <motion.div className='menuBlackPane'>
                    <motion.div className='col-3'>
                        <motion.div className='item'>
                            <p className='topic'>Contact</p>

                            <div className='address-box'>
                                <p className='address'>9/1 หมู่ที่ 5 ถนนพหลโยธิน ตำบลคลองหนึ่ง</p>
                                <p className='address'>อำเภอคลองหลวง จังหวัดปทุมธานี 12120</p>
                            </div>
                            <div className='address-box'>
                                <p className='address'>+66 2 407 3888 ต่อ 2430</p>
                                <p className='address'>
                                    <Link to='http://www.bu.ac.th/digital-media'>http://www.bu.ac.th/digital-media</Link>
                                </p>
                            </div>
                        </motion.div>
                        <motion.div className='item'>
                            <p className='topic'>Social</p>

                            <ul>
                                <li><a href='#'>Facebook</a></li>
                                <li><a href='#'>Youtube</a></li>
                                <li><a href='#'>Twitter</a></li>
                                <li><a href='#'>Instagram</a></li>
                            </ul>
                        </motion.div>
                        <motion.div className='item'>
                            <p className='topic'>Menu</p>

                            <ul>
                                <li><Link to='/'>Home</Link></li>
                                <li><Link to='/projects'>Projects</Link></li>
                                <li><Link to='/projects'>News</Link></li>
                                <li><Link to='/contact'>Contact</Link></li>
                            </ul>
                        </motion.div>
                    </motion.div>
                </motion.div>
                <motion.div className='imageFooterBanner'
                    style={{
                        backgroundImage: 'url(/img/image-footer.jpg)'
                    }}
                >
                    <img className='buLogo' alt='BUDM-Logo' src='/img/BUDM-Logo.png'/>
                </motion.div>
                <motion.div className='bottomLine'>
                    <p>Copyright © 2024 Bangkok University. All rights reserved</p>
                    <Link to='/admin' target='_blank'><FontAwesomeIcon icon={faSignIn} /></Link>
                </motion.div>
            </motion.div>
        );
    }
}

Footer.contextType = AppState;

export default Footer;