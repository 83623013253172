import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import style from './index.module.scss';

import { AppState, IThemeState } from '../../../context';
import MediaContent from '../MediaContent';
import { genUID } from '../../../lib/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';


type PageProps = {
    items: any[];
    onSelect: (item: any) => void;
    value: any;
};
type PageState = {
    propKey: string;
    mounted: boolean;
    showDropdown: boolean;
};
class DropdownFilter extends React.Component<PageProps, PageState, IThemeState> {
    context!: IThemeState;

    state: PageState = {
        propKey: genUID(),
        mounted: false,
        showDropdown: false
    };

    constructor(props: PageProps) {
        super(props);
        this.handleOthersClick = this.handleOthersClick.bind(this);
    }

    componentDidMount() {
        window.addEventListener('click', this.handleOthersClick);
    }

    componentWillUnmount(): void {
        window.removeEventListener('click', this.handleOthersClick);
    }

    handleOthersClick = (e: any) => {
        console.log('handleOthersClick', e)
        if(e.target.classList.contains('dropdown-crr-select')
            && e.target.getAttribute('data-prop-key') === this.state.propKey
        ) return;
        
        this.setState({
            showDropdown: false
        });
    }

    getParentNode(e: any) {
        return e.target.parentElement.parentElement;
    }

    render() {
        const { mounted, propKey, showDropdown } = this.state;

        let {
            items,
            onSelect,
            value
        } = this.props;

        let crrItem = items.find(item => item.value === value) || items[0];
        // console.log(crrItem, value)
        
        return (
            <motion.div className='filter-dropdown'>
                <motion.div className={`dropdown-crr-select ${showDropdown ? 'active' : ''}`}
                    data-prop-key={propKey}
                    onClick={() => {
                        this.setState({
                            showDropdown: !showDropdown
                        })
                    }}
                >
                    <p className='name'>{crrItem?.name}</p>
                    {!showDropdown && <FontAwesomeIcon className='arrow' icon={faChevronUp}/>}
                    {showDropdown && <FontAwesomeIcon className='arrow' icon={faChevronDown}/>}
                </motion.div>

                <motion.div className={`dropdown-items ${showDropdown ? 'active' : ''}`}>
                    <ul>
                        {items.map(item => (
                            <li key={propKey + item.value} className={item.value === value ? 'active' : ''}
                                onClick={() => {
                                    onSelect(item.value);
                                    this.setState({
                                        showDropdown: false
                                    })
                                }}
                            >
                                {item.img && <img src={item.img} alt='exhibition logo' />}
                                <div>{item.name}</div>
                            </li>
                        ))}
                    </ul>
                </motion.div>
            </motion.div>
        );
    }
}

DropdownFilter.contextType = AppState;

export default DropdownFilter;