import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import DOMPurify from "dompurify";

import './index.scss';

import { AppState, IThemeState } from '../context';
import HeroLanding from '../landing/components/HeroLanding';
import { withRouter, WithRouterProps } from '../common/withRouter';
import NewsBlock from '../news/components/NewsBlock';
import PageContainer from '../common/PageContainer';

interface Params {
    newsId: string;
}
  
type PageProps = WithRouterProps<Params>;

type PageState = {
    mounted: boolean;
    title: string;
    date: string;
    img: string;
    content: string;
};
class NewsReadPage extends React.Component<PageProps, PageState, IThemeState> {
    context!: IThemeState;

    state: PageState = {
        mounted: false,
        title: '',
        date: '',
        img: '',
        content: '',
    };

    previousScrollY = window.scrollY;

    constructor(props: PageProps) {
        super(props);
        this.previousScrollY = window.scrollY;
        // window.scrollTo(0, 0);
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        });
        this.setState({
            mounted: true,
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis rutrum tellus, sit amet mattis turpis. Curabitur euismod sollicitudin dolor. Aenean a nulla sed urna condimentum vehicula varius tincidunt nisi. Donec pretium risus at lectus eleifend, at consectetur odio finibus. Nullam vel nisl erat. Nullam vitae dui ullamcorper, lobortis mi nec, lobortis orci. Suspendisse malesuada lacus nec elit finibus tempor. Cras gravida arcu sed eleifend tincidunt. Sed a ultricies massa. Etiam risus ex, convallis in orci at, facilisis euismod odio.

            In libero odio, eleifend vel consectetur non, suscipit eget risus. Sed a ligula vel tortor vestibulum vestibulum. Mauris eu mauris feugiat, iaculis odio sit amet, porta felis. Donec vitae felis ac purus auctor tincidunt. Etiam leo velit, lobortis eu sem vitae, malesuada egestas eros. In nec faucibus lectus. Fusce sodales, nisl ac congue posuere, leo lorem posuere augue, consectetur vehicula est mauris eu mi. Praesent id rhoncus ligula. Sed aliquet, sapien non finibus tempor, nisl justo imperdiet turpis, id maximus tortor libero vitae ligula. Pellentesque blandit sed nisl sed finibus. Maecenas sagittis, leo vel bibendum dictum, est turpis iaculis ligula, vulputate maximus enim metus sit amet urna. Duis pulvinar sem id leo efficitur, et lobortis tortor ultrices. Proin vulputate tortor at libero dignissim cursus. Sed tempus eros vel purus elementum, eget bibendum orci dictum. Morbi suscipit tincidunt pretium.
            `,
        });
        
    }

    componentWillUnmount(): void {
        if(this.state.mounted && window.location.pathname === '/news') {
            setTimeout(() => {
                window.scrollTo(0, this.previousScrollY);
            }, 10);
        }
    }

    render() {
        const { mounted, title, date, img, content } = this.state;

        const { newsId } = this.props.match.params;
        
        console.log(this.props.match.params)

        const sanitizedHtmlContent = DOMPurify.sanitize(content);
        return (
            <PageContainer>
                <AnimatePresence mode='wait'>
                    <motion.div className='news-read-page'>
                        <HeroLanding pageHeight={'80vh'} />
                        <motion.div className='news-read-container'>
                            <motion.div className='article-content'>
                                <motion.div className='article-thumbnail'
                                    style={{
                                        backgroundImage: `url(${'https://picsum.photos/900/500?news-random'})`,
                                    }}
                                ></motion.div>
                                <motion.h1 className='article-title'>Lorem Ipsum is simply dummy text of the printing and</motion.h1>
                                <p>Jan, 26 2024</p>
        
        
                                <motion.div className='article-content-txt'>
                                    <p className='sub-text'>Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum is simply dummy text of the printing and</p>
                                    <div className='content-display' dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}></div>
                                </motion.div>
                            </motion.div>
                            <motion.div className='topic-title'>
                                <p>Latest News</p>
                                <div className="hr"></div>
                            </motion.div>
                            <motion.div className='news-list'>
                                {Array.from(Array(2).keys()).map(i => (
                                    <NewsBlock
                                        key={`news-landing-${i}`}
                                        img=''
                                        name='Lorem Ipsum is simply dummy text of the printing and'
                                        date='Jan, 26 2024'
                                        itemKey={`/news/${i+1}`}
                                        link='/news/1'
                                    />
                                ))}
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </AnimatePresence>
            </PageContainer>
        );
    }
}

NewsReadPage.contextType = AppState;

// export default withRouter(NewsReadPage);
export default withRouter(NewsReadPage);